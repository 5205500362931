@font-face {
  font-family: 'SF Pro Text';
  src: local('SF Pro Text Light') url('../assets/fonts/SF-Pro-Text-Light.otf')
    format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: local('SF Pro Text Light Italic')
    url('../assets/fonts/SF-Pro-Text-LightItalic.otf');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Text';
  src: local('SF Pro Text Regular')
    url('../assets/fonts/SF-Pro-Text-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: local('SF Pro Text Regular Italic')
    url('../assets/fonts/SF-Pro-Text-RegularItalic.otf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Text';
  src: local('SF Pro Text Medium') url('../assets/fonts/SF-Pro-Text-Medium.otf')
    format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: local('SF Pro Text Medium Italic')
    url('../assets/fonts/SF-Pro-Text-MediumItalic.otf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Text';
  src: local('SF Pro Text Semibold')
    url('../assets/fonts/SF-Pro-Text-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: local('SF Pro Text Semibold Italic')
    url('../assets/fonts/SF-Pro-Text-SemiboldItalic.otf');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Text';
  src: local('SF Pro Text Bold') url('../assets/fonts/SF-Pro-Text-Bold.otf')
    format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: local('SF Pro Text Bold Italic')
    url('../assets/fonts/SF-Pro-Text-BoldItalic.otf');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Text';
  src: local('SF Pro Text Heavy') url('../assets/fonts/SF-Pro-Text-Heavy.otf')
    format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Text';
  src: local('SF Pro Text Heavy Italic')
    url('../assets/fonts/SF-Pro-Text-HeavyItalic.otf');
  font-weight: 800;
  font-style: italic;
}
