@import 'variable';
@import 'fonts';
@import 'typography';
@import 'layout';

* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: 'SF Pro Text', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(
    to left,
    rgba(160, 212, 142, 0.2),
    rgba(40, 163, 182, 0.4)
  );
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
