.SignInLayout {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.DefaultLayout {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .Header {
    &.header-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
      width: 100%;
      height: 70px;
      background: #fff;
    }
    .header-content {
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      align-items: stretch;
      width: 100%;
      height: 100%;
      max-width: 1280px;
      .left-menu {
        a.brand-link {
          display: flex;
          height: 100%;
          padding: 10px 5px;
          img {
            height: 100%;
          }
        }
      }
      .nav-menu {
        flex-shrink: 0;
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }

      .user-menu {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }
    }
  }
}
