.heading-1 {
  font-size: 56px;
}

.heading-2 {
  font-size: 48px;
}

.heading-3 {
  font-size: 32px;
}

.heading-4 {
  font-size: 28px;
}

.heading-5 {
  font-size: 22px;
}

.heading-6 {
  font-size: 18px;
}

.body-text {
  font-size: 14px;
}
